'use client';

import React from 'react';
import { ExtendCSS, Flex } from 'vcc-ui';
import { Click } from '@vcc-www/buttons/Click';
import { LinkField } from '@vcc-www/content-management-jss-client';
import { Markdown_DEPRECATED_VCCUI } from '@vcc-www/markdown';

export type DisclaimerProps = {
  text?: string;
  textSource?: string;
  extend?: ExtendCSS;
  textExtend?: ExtendCSS;
  link?: LinkField;
  linkHrefSource?: string;
  linkTextSource?: string;
  enableTracking?: boolean;
  alignSelfStart?: boolean;
  alignTextStart?: boolean;
  disableDataNoSnippet?: boolean;
  testId?: string;
};

const Disclaimer: React.FC<React.PropsWithChildren<DisclaimerProps>> = ({
  text,
  textSource,
  extend,
  textExtend,
  link,
  linkHrefSource,
  linkTextSource,
  enableTracking,
  alignSelfStart,
  alignTextStart,
  disableDataNoSnippet = false,
  testId,
}) => {
  return text || link ? (
    <Flex
      extend={[containerCSS(alignSelfStart, alignTextStart), extend]}
      data-nosnippet={!disableDataNoSnippet || undefined}
    >
      {text && (
        <Markdown_DEPRECATED_VCCUI
          variant="bates"
          markdown={text}
          extend={[textCSS, textExtend]}
          enableTracking={enableTracking}
          data-testid={testId}
          data-autoid={testId}
          data-sources={textSource}
        />
      )}
      {!!link?.href && !!link?.text && (
        <small data-autoid={`${testId}-link`}>
          <Click
            extend={linkCSS}
            href={link.href}
            target={link.target}
            trackEventLabel={link.href}
            data-autoid={`${testId}-link-text`}
            data-sources={[linkHrefSource, linkTextSource]}
          >
            {link.text}
          </Click>
        </small>
      )}
    </Flex>
  ) : null;
};

const textCSS: ExtendCSS = ({ theme: { color } }) => ({
  color: color.foreground.secondary,
});

const linkCSS: ExtendCSS = ({ theme: { color } }) => ({
  color: color.foreground.action,
  ':hover': {
    color: color.foreground.primary,
  },
});

const containerCSS =
  (alignSelfStart?: boolean, alignTextStart?: boolean): ExtendCSS =>
  () => ({
    width: '100%',
    fromM: {
      alignSelf: alignSelfStart ? 'flex-start' : 'center',
      textAlign: alignTextStart ? 'start' : 'center',
    },
  });

export default Disclaimer;
