'use client';

import React, { HTMLAttributes, useEffect, useRef } from 'react';
import { Block, ExtendCSS } from 'vcc-ui';
import {
  CarModel,
  GenericValue,
  LeaseType,
  Powertrain,
  useTracker,
} from '@volvo-cars/tracking';

export interface GA3Strict {
  selectedDealerName?: GenericValue;
  currencyCode?: GenericValue;
  carDrivelineType?: Powertrain | GenericValue;
  carModelName?: CarModel | GenericValue;
  market?: GenericValue;
  carEngine?: GenericValue;
  pageName?: GenericValue;
  pageType?: GenericValue;
  subPageName?: GenericValue;
  countryName?: GenericValue;
  marketLanguage?: GenericValue;
  signedIn?: 0 | 1;
  eventCategory?: GenericValue;
  eventAction?: GenericValue;
  eventLabel?: GenericValue;
  durationMileage?: GenericValue;
  leaseType?: LeaseType;
}

type RichTextProps = HTMLAttributes<HTMLDivElement> & {
  extend?: ExtendCSS;
  html?: string;
  enableTracking?: boolean;
  trackEventLabel?: ((linkText: string, linkUrl: string) => string) | string;
  trackEventAction?: string;
  trackEventCategory?: ((linkText: string, linkUrl: string) => string) | string;
  trackGA3?: { [name: string]: GenericValue } | GA3Strict;
};

export const TrackLinks: React.FC<
  React.PropsWithChildren<
    Pick<
      RichTextProps,
      'trackEventLabel' | 'trackEventAction' | 'trackEventCategory' | 'trackGA3'
    >
  >
> = ({
  trackEventLabel,
  trackEventAction,
  trackEventCategory,
  trackGA3,
  children,
}) => {
  const divRef = useRef<HTMLElement>(null);
  const track = useTracker(null, { ga3: trackGA3 });

  useEffect(() => {
    divRef.current?.addEventListener('click', (e) => {
      const target = e.target as HTMLAnchorElement;
      if (target.tagName === 'A') {
        const linkText = target.text.toLowerCase().replace(/ /g, '_');

        const eventLabel =
          typeof trackEventLabel === 'function'
            ? trackEventLabel(target.text, target.href)
            : trackEventLabel;

        const eventCategory =
          typeof trackEventCategory === 'function'
            ? trackEventCategory(target.text, target.href)
            : trackEventCategory;

        track.interaction({
          eventLabel: eventLabel ?? `${target.pathname}|${linkText}`,
          eventAction: trackEventAction ?? 'link',
          ...(eventCategory && { eventCategory }),
        });
      }
    });
  }, [divRef, track, trackEventAction, trackEventLabel, trackEventCategory]);

  const child = React.Children.only(children);

  return React.cloneElement(child as React.ReactElement, { ref: divRef });
};

const RichText: React.FC<React.PropsWithChildren<RichTextProps>> = ({
  extend,
  html,
  enableTracking,
  trackEventLabel,
  trackEventAction,
  trackEventCategory,
  trackGA3,
  ...props
}) => {
  const content = html ? (
    <Block
      extend={extend}
      dangerouslySetInnerHTML={{ __html: html }}
      {...props}
    />
  ) : null;

  return enableTracking && html ? (
    <TrackLinks
      trackEventLabel={trackEventLabel}
      trackEventAction={trackEventAction}
      trackEventCategory={trackEventCategory}
      trackGA3={trackGA3}
    >
      {content}
    </TrackLinks>
  ) : (
    content
  );
};

export default RichText;
